<template>
    <div class="statistics_unplan">
        <div class="filter">
            <div class="item">
                <div class="left">所属地区</div>
                <el-select size="medium" v-model="area" popper-class="global_select_popper" :disabled="statistics_power.area>0">
                    <el-option v-for="item in areaList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>
            <div class="item">
                <div class="left">投入来源</div>
                <el-select size="medium" v-model="source" popper-class="global_select_popper">
                    <el-option v-for="item in sourceList" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>
            <div class="item">
                <div class="left">项目类别</div>
                <el-select size="medium" v-model="project_type" popper-class="global_select_popper">
                    <el-option v-for="item in projectType" :key="item.value" :label="item.label" :value="item.value"></el-option>
                </el-select>
            </div>
            <div class="item btn">
                <el-button size="small" type="primary" class="button_88px" @click="search">查询</el-button>
            </div>
        </div>
        <div class="table">
            <el-table :data="tableData" style="width:100%" height="100%" v-loading="loading">
                <el-table-column prop="project_number" label="序号" align="center"></el-table-column>
                <el-table-column prop="project_name" label="项目名称" align="center"></el-table-column>
                <el-table-column label="所属地区" align="center">
                    <template slot-scope="scope">
                        <span>{{initArea(scope.row.area)}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="投入来源" align="center">
                    <template slot-scope="scope">
                        <span>{{initSource(scope.row.source)}}</span>
                    </template>
                </el-table-column>
                <el-table-column label="项目类别" align="center">
                    <template slot-scope="scope">
                        <span>{{initProjectType(scope.row.project_type)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="project_input" label="项目投入(万元)" align="center"></el-table-column>
            </el-table>
            <div class="pagination">
                <el-pagination @current-change="pageChange" :current-page="page" :page-size="pagesize" layout="total, prev, pager, next, jumper" :total="total"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
    import api from '@/api/methods/index';
    import computedData from '@/mixins/computedData.js';
    export default {
        mixins:[computedData],
        data(){
            return{
                tableData: [], page:1,pagesize:15,total:0, loading:false,
                area:0,
                areaList:[
                    {value:0, label:'全部(默认)'},
                    {value:1, label:'州本级'},
                    {value:2, label:'玛沁县'},
                    {value:5, label:'甘德县'},
                    {value:6, label:'达日县'},
                    {value:4, label:'班玛县'},
                    {value:3, label:'久治县'},
                    {value:7, label:'玛多县'},
                ],
                source:0,
                sourceList:[
                    {value:0, label:'全部(默认)'},
                    {value:1, label:'财政资金'},
                    {value:2, label:'社会组织'},
                    {value:3, label:'个人'},
                    {value: 4, label: '企业资金'},
                ],
                project_type:0,
                projectType:[
                    {value:0, label:'全部(默认)'},
                    {value:1, label:'区县'},
                    {value:2, label:'街镇'},
                    {value:3, label:'村村'},
                    {value:4, label:'村企'},
                    {value:5, label:'其他'},
                ]
            }
        },
        computed:{
            statistics_power() {
                return this.$store.state.user.current_user.statistics_power
            }
        },
        created(){
            if(this.statistics_power.area>0){
                this.area=this.statistics_power.area;
            }
            this.offPlanLists()
        },
        methods:{
            offPlanLists(){
                this.loading=true;
                this.tableData=[];
                let param={page:this.page,pagesize:this.pagesize};
                if(this.area) param.area=this.area;
                if(this.source) param.source=this.source;
                if(this.project_type) param.project_type=this.project_type;
                api.unplanned.offPlanLists(param).then(res=>{
                    if(res.data){
                        this.total=res.data.count||0;
                        this.tableData=res.data.lists||[];
                        this.tableData.forEach(item=>{
                            if(item.project_input!==''&&item.project_input!==undefined&&item.project_input!==null){
                                item.project_input=item.project_input/10000+'万元'
                            }else{
                                item.project_input=0;
                            }
                        })
                    }
                    this.loading=false;
                })
            },
            pageChange(page){
                this.page=page;
                this.offPlanLists();
            },
            search(){
                this.page=1;
                this.offPlanLists();
            }
        },
    }
</script>

<style scoped lang="less">
.statistics_unplan{
    height:100%;
    display:flex;
    flex-direction: column;
    .filter{
        display:flex;
        flex-wrap: wrap;
        padding:30px 0 10px 0;
        .item{
            margin-bottom: 20px;
            display:flex;
            align-items: center;
            flex:0 0 308px;
            margin-right: 30px;
            &.btn{
                flex:0 0 88px;
            }
            .left{
                flex:0 0 75px;
            }
        }
    }
    .table{
        flex:1;
        min-height: 0;
    }
}
</style>